<template>
  <div class="hello container text-center">
    <img :src="imgLink" class="rounded mx-auto d-block w-100">

    <h1 class="mt-2 fw-bold">{{ gameName }}</h1>
    <h2 class="mt-4 fw-bold">Thank you for your patient,</h2>
    <h2 class="pb-5 fw-bold">Download will began shortly</h2>

    <a v-if="showLink" class="pb-5 fw-bold" :href="Link">Click Here if download did not start</a>
  </div>
</template>

<script>
import axios from 'axios';



// 检查是否是iOS设备
function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
}

// 检查是否是iPad设备
function isIPad() {
  return /iPad/i.test(navigator.userAgent) && !window.MSStream;
}

// 检查是否是Android设备
function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export default {
  data() {
    return {
      OS: "",
      Link: "",
      showLink: true
    };
  },
  props: {
    imgLink: String,
    gameName: String,
    AppName: String,
  },
  created() {
    let params = {};
    if (isIOS() || isIPad()) {
      this.OS = "IOS";
      // 如果是iPad设备，设置iPad相关参数
      params = {
        AppName: this.AppName,
        os: 'android'
        // 其他iPad相关参数
      };
    } else if (isAndroid()) {
      this.OS = "Android";
      // 如果是Android设备，设置Android相关参数
      params = {
        AppName: this.AppName,
        os: 'android'
        // 其他Android相关参数
      };
    } else {
      this.OS = "Another";
      params = {
        AppName: this.AppName,
        os: 'android'
        // 其他Android相关参数
      };
      // this.showLink = false;
    }
    axios.get('https://bo.app96.cc/VueAPI/AppVession.php', {
      params: params
    })
      .then(response => {
        // 从API返回的数据中获取Link属性的值
        this.Link = response.data[0].Link;
        // if (isIOS() || isIPad()) {
        //   window.location.href = response.data[0].Link;
        //   this.showLink = false;
        // }
        // console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  },

}
</script>

